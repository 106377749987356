import {  useState } from "react";

import { LanguageContext } from "../Contexts";


interface LanguageContextProviderProps {
  children: React.ReactNode;
}

export const LanguageContextProvider = ({
  children,
}: LanguageContextProviderProps) => {
  const [languageName, setLanguageName] = useState<any>("");
  const [id, setId]=useState('')
;

  return (
    <LanguageContext.Provider value={{languageName, setLanguageName, id, setId }}>
      {children}
    </LanguageContext.Provider>
  );
};

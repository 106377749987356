import { useState } from "react";

import { PracticeExamsContext } from "../Contexts";

interface PracticeExamsContextProviderProps {
  children: React.ReactNode;
}

export const PracticeContextProvider = ({
  children,
}: PracticeExamsContextProviderProps) => {
  const [examName, setExamName] = useState<any>("");
  const [id, setId] = useState("");
  return (
    <PracticeExamsContext.Provider value={{ examName, setExamName, id, setId }}>
      {children}
    </PracticeExamsContext.Provider>
  );
};
